// Misc.
    $misc: (
        z-index-base:   10000
    );

// Duration.
    $duration: (
        navPanel: 0.5s
    );

// Size.
    $size: (
        navPanel: 275px,
        radius: 5px
    );

// Font.
    $font: (
    );

// Palette.
    $palette: (
        bg: #fff,
        bg-bold: #f7f7f7,
        fg: #474747,
        fg-bold: #4c4c4c,
        fg-light: #999,
        border: #e0e0e0,

        accent: (
            bg: #002534,
            fg: #fff
        ),
        obscure: (
            bg: #333,
            bg-light: #555,
            bg-bold: #1f1f1f,
            fg: #c0c0c0
        )
    );
