/* This file intentionally left blank.
 * Put your custom CSS into your (not the theme's) assets/sass/libs/_custom_vars.scss
 */

$palette: (
    bg: #ffffff,
    bg-bold: #fff,
    fg: #013d47,
    fg-bold: #4c4c4c,
    fg-light: #999,
    border: #e0e0e0,
    mission: #d7e0e3,

    accent: (
        bg: #012636,
        fg: #008ea1,
    ),
    obscure: (
        bg: #fff,
        bg-light: #555,
        bg-bold: #1f1f1f,
        fg: #012636,
    ),
);
$size: (
    navPanel: 200px,
    radius: 5px,
);
