/* This file intentionally left blank.
 * Put your custom CSS into your (not the theme's) assets/sass/custom.scss
 */

#logo-wrapper {
    display: block;
    position: relative;
    padding-top: 0.6em;
    margin-left: 2em;
    width: 255px;
    height: 1px;
}

#logo-wrapper a {
    border: 0;
}

#header {
    padding-top: 0em;
}

#header #nav {
    background-color: white;
}

#body {
    background-color: _palette(bg);
    color: _palette(fg);
}

#footer {
    background-color: white;
}

body,
input,
select,
textarea {
    color: _palette(fg);
    font-family: "Roboto", sans-serif;
    font-size: 16pt;
    font-weight: 300;
    line-height: 1.65em;
}

body,
input,
select,
textarea {
    font-family: "Roboto", sans-serif;
}

body {
    background: _palette(bg-bold);
}

#nav {
    cursor: default;
    background-color: _palette(obscure, bg);
    padding: 0;

    &:after {
        content: "";
        display: block;
        width: 100%;
        height: 0.2em;
        background-color: _palette(accent, bg);
    }

    > ul {
        margin: 0;
        padding-right: 1em;
        padding-bottom: 0.75em;
        height: auto;
        display: block;

        > li {
            position: relative;
            z-index: 1;
            display: inline-block;
            margin-left: 0em;

            a {
                color: _palette(obscure, fg);
                text-decoration: none;
                border: 0;
                display: block;
                padding: 0.5em 0.5em 0.5em 0.5em;
            }

            &.opener a::after {
                content: " ▾";
            }

            &:first-child {
                margin-left: 0;
            }

            &:hover {
                a {
                    color: _palette(accent, fg);
                }
            }

            &.current {
                font-weight: 600;

                &:before {
                    //          @include vendor('transform', 'rotateZ(45deg)');
                    width: 0.5em;
                    height: 0.5em;
                    content: "";
                    display: block;
                    position: absolute;
                    bottom: -0.5em;
                    left: 50%;
                    margin-left: -0.375em;
                    background-color: _palette(accent, bg);
                    background-image: url("images/bg01.png");
                }

                a {
                    color: _palette(accent, fg);
                }
            }

            &.active {
                a {
                    color: _palette(accent, fg);
                }

                &.current {
                    &:before {
                        opacity: 0;
                    }
                }
            }

            > ul {
                display: none;
            }
        }
    }
}

#circle {
    width: 60%;
    background-color: rgb(225, 230, 232);
    border-style: solid;
    border-width: 0px;
    border-radius: 50%;
    margin: 0 auto;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 1em;
    box-sizing: border-box;
    flex-direction: column;
    box-shadow: 0 0 200px 100px rgba(215, 224, 227, 1);
    position: relative;
}

#circle h1 {
    display: block;
    color: #003d48;
    font-size: 2em;
    font-weight: lighter;
    margin-bottom: 1em;
}

#circle p {
    font-size: 1.5em;
    font-weight: bold;
    font-style: italic;
    margin: 0 0 1em 0;
}

// buttons
#circle a {
    font-size: 0.7em;
    //font-weight: bold;
    border: none;
    text-transform: uppercase;
    text-decoration-line: underline;
    margin-left: auto;
}

#circle a:after {
    content: " →";
}

#projects a {
    position: relative;
    z-index: 1;
    font-size: 0.7em;
    border: none;
    text-transform: uppercase;
}

#projects a:not(.image):after {
    content: " →";
}

.box {
    &.project {
        position: relative;
        text-align: center;
        background-color: white;

        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 0 20px 0 rgba(215, 224, 227, 1);

        p {
            margin: 0;
        }

        .image {
            width: 100%;
        }

        h2 {
            margin-bottom: 0;
        }
    }

    &.project:hover {
        background: rgb(255, 255, 255);
        background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 35%,
            rgba(215, 224, 227, 0.5) 100%
        );
    }
}

table {
    width: 0%;

    & th {
        border-bottom: 1px;
        border-style: dashed;
    }

    & th,
    td {
        padding-right: 10px;
        white-space: nowrap;
    }
}

#page-wrapper {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    justify-content: flex-start;
}

#footer {
    padding: 1em;
    margin-top: auto;
}

#faq {
    padding-bottom: 10px;

    & p {
        padding-left: 30px;
    }
}
